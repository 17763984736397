.ant-checkbox-group {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-bottom: 32px;

  .ant-checkbox-wrapper {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #4f4f4f;
  }
}

.app-user__info {
  margin-top: 24px;
  margin-bottom: 20px;

  p {
    font-family: Montserrat;
    font-style: normal;
    color: #313748;
    line-height: 18px;
    display: flex;
    align-items: center;
    margin-bottom: 0;

    &:first-child {
      font-weight: 600;
      font-size: 13px;
    }

    &:last-child {
      font-weight: 300;
      font-size: 11px;
    }
  }
}
