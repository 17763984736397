.home-controller__container {
  .card-title {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #252631;
    text-transform: uppercase;
  }

  .user-info__label-field {
    font-family: Montserrat;
    font-style: normal;
    color: #2e499c;

    &.user-info__label-field--big {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
    }

    &.user-info__label-field--small {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
    }

    &.user-info__label-field--light {
      color: #92929d;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .user-info__label-content {
    font-family: Montserrat;
    font-style: normal;
    font-size: 13px;
    line-height: 16px;
    color: #313748;

    &.user-info__label-content--light {
      font-weight: normal;
    }

    &.user-info__label-content--bold {
      font-weight: bold;
    }
  }

  .user-info__wrapper {
    .ant-card {
      border-radius: 4px !important;
    }

    .user-info__container {
      .user-info__header {
        position: relative;
        margin-bottom: 24px;

        .edit-icon {
          font-size: 18px;
          position: absolute;
          cursor: pointer;
          top: 0px;
          right: 0px;
        }

        .user-info__image {
          position: relative;
          border-radius: 50%;
          height: 100px;
          width: 100px;

          img {
            height: 100%;
            width: 100%;
          }

          .user-info__status {
            position: absolute;
            height: 16px;
            width: 16px;
            bottom: 1px;
            right: 12px;

            img {
              height: 100%;
              width: 100%;
            }
          }
        }
      }

      .user-info__content {
        border-top: 1px solid #f1f1f5;
        border-bottom: 1px solid #f1f1f5;
        padding: 24px 0px;

        .control-center {
          gap: 8px;
        }
      }

      .user-info__footer {
        div {
          cursor: pointer;
        }

        .btn-save {
          color: #f8f9fb;
          background: #2e499c;
          border-radius: 10px;
          padding: 8px 16px;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          outline: none;
          border: 0px;
        }

        .btn-cancel {
          background: #f8f9fb;
          border-radius: 10px;
          padding: 8px 16px;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #2e499c;
          outline: none;
          border: 0px;
        }
      }
    }
  }

  .ticket__container {
    background: #ffffff;
    .empty-ticket {
      img {
        width: 168px;
        height: 104px;
      }
    }
    .ticket__item {
      border: 1px solid #f1f1f5;
      border-radius: 10px;
      padding: 16px;
    }
  }

  .table__container {
    background-color: #ffffff;
    padding-bottom: 16px;
    border-radius: 4px;

    .table__header {
      padding: 16px;
    }

    .table__content {
      tr {
        td {
          border-bottom: none;
        }
      }
    }
  }

  .list-circle__container {
    width: 100%;
    height: 40px;
    position: relative;

    .circle-item {
      width: 36px;
      height: 36px;
      // border: 2px solid #FFFFFF;
      border-radius: 50%;
      position: absolute;
      background-color: #f2f4f6;

      img {
        width: 100%;
        height: 100%;
      }

      span {
        font-size: 14px;
        line-height: 21px;
        color: #98a9bc;
      }
    }
  }

  .card__container {
    padding: 32px 20px;
    background-color: #ffffff;
    overflow-y: hidden;
    cursor: pointer;

    .card__image {
      height: 64px;
      width: 64px;
      margin-right: 16px;
      border-radius: 50%;
      border: 1px solid #dce7ff;
      flex-shrink: 0;

      img {
        border-radius: 50%;
        height: 100%;
        width: 100%;
      }

      i {
        font-size: 24px;
        color: #000000;
      }
    }

    .card__info {
      flex-grow: 1;
      overflow-y: hidden;

      .info__title {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.1px;
        color: #171725;
      }

      .info__description {
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 16px;
        color: #92929d;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .info__action {
        background: #dce7ff;
        border-radius: 10px;
        padding: 4px 8px;
        color: #2e499c;
        width: 45px;

        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #2e499c;

        span {
          cursor: pointer;
        }
      }
    }
  }
}
