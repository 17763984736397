@import "../../../../assets/scss/utils/mixins";
/* Style for InputNumber.tsx */
.input-number__container {
    width: 100%;
    min-width: 100px;
    border-radius: 7px;
    position: relative;
    .input-number__icon {
        &:hover {
            opacity: 0.7;
        }
    }
}
