@import "../../../../assets/scss/utils/mixins";
/* Style for InputText.tsx */
.input-text__container {
  width: 100%;
  border-radius: 7px;
  position: relative;
  .input-text__icon {
    &:hover {
      opacity: 0.7;
    }
  }
}
