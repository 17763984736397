.ticket__container {
  height: 100%;

  .page__master-table {
    height: 100%;
    padding: 0 !important;
  }

  .ticket__status {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    padding: 8px 16px;
    width: fit-content;

    &.ticket__status--wait {
      color: #dc3545;
      background: #ffd7da;
    }

    &.ticket__status--do {
      color: #f2994a;
      background: #fff4de;
    }

    &.ticket__status--done {
      color: #27ae60;
      background: #dffff2;
    }
  }
}
