.breakword {
  word-break: break-all;
}

.firmware__container {
  .page__master-table {
    .ant-card {
      .ant-card-body {
        padding: 0 !important;
      }
    }
  }
}

.firmware__description {
  line-height: 2;

  h1 {
    font-size: 1.2rem;
  }

  h2 {
    font-size: 1.1rem;
  }

  h3 {
    font-size: 1.05rem;
  }

  ul,
  ol {
    list-style-type: initial;
    padding-left: 24px;
  }

  img {
    display: block;
    padding: 0.25rem;
    border: 1px solid var(--blue);
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  img + br + em {
    display: inherit;
    text-align: center;
    font-size: 90%;
  }

  a {
    background-color: transparent;
    text-decoration: none;
    text-decoration-skip: objects;
  }
  a:active,
  a:hover {
    outline-width: 0;
    text-decoration: none;
  }

  table {
    border-collapse: collapse;
    margin-left: auto;
    margin-right: auto;
  }

  td,
  th {
    border: 1px solid var(--text);
    padding: 8px;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: var(--bg-nav);
  }

  pre {
    padding: 0.5rem;
    background-color: var(--bg);
    border-radius: var(--border-radius);
    overflow-x: scroll;
  }

  code {
    background-color: var(--bg);
    padding: 0.2rem;
    color: var(--text);
    border-radius: var(--border-radius);
    font-size: 0.95rem;
  }

  pre > code {
    background-color: transparent;
    border-radius: 0;
  }
}
