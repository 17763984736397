.home-controller-logs-modal__container {
  .home-controller-logs-modal__header {
    padding: 16px;
    border-bottom: 1px solid #f2f2f2;
    span {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #252631;
    }
    div {
      color: #313748;
      background: #f8f9fb;
      border-radius: 50%;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .home-controller-logs-modal__body {
    padding: 16px 24px;

    .body__filter-area {
      width: 100%;
    }

    .body__content-area {
      width: 100%;
      margin-top: 24px;
    }
  }

  .home-controller-logs__item {
    border-radius: 4px;
    border: 1px solid #232a36;
  }
}
