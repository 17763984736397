@import '../../../assets/scss/utils/mixins';

/* Style for ContentModal.tsx */
.modal__container {
    .modal__table {
        margin-top: 15px;
    }
    
    .ant-modal-body {
        padding: 0px !important;
    }

    .modal_content {
        position: relative;
        display: block;
        width: 100%;
        height: auto;

        .loading-block {
            display: flex; 
            justify-content: center;
            align-items: center;
            width: 300px;
            height: 300px;
        }

        .preview__containter {
            display: flex;
            margin-top: 15px;
            padding-top: 10px;

            .preview__left-side {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;

                .preview__header {
                    display: flex;

                    .preview__vertical-bar {
                        width: 5px;
                        height: 50px;
                        @include background-color();
                    }

                    .preview__header-info {
                        margin-left: 15px;
                        display: flex;
                        justify-content: space-between;
                        width: 100%;

                        .preview__header-text {
                            display: flex;
                            flex-direction: column;

                            .preview__header-title {
                                font-weight: bold;
                                font-size: 20px;
                                line-height: 27px;
                                @include gradient-text();
                            }

                            .preview__header-date {
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 21px;          
                                color: #8181A5;
                            }
                        }
                    }
                }

                .preview__body {
                    flex-grow: 1;
                    overflow-y: auto;
                    margin: 10px 15px 10px 15px;
                    max-height: 600px;
                    .preview__content {
                        display: flex;
                        justify-content: center;
                        border-top: 1px solid #EEEEEE;
                        flex-direction: column;
                        align-items: center;
                        padding: 10px;

                        .gradient-text {
                            @include gradient-text();
                        }

                        .transform-text {
                            font-weight: bold;
                            font-size: 14px;
                            line-height: 21px;
                        }

                        img {
                            height: 200px;
                            width: 200px;
                        }
                    }
                }

                .preview__footer {
                    padding-left: 20px;
                }
            }

            .preview__right-side {
                width: 33.3%;
                height: auto;
            }
        }
    }

    .ant-modal-footer {
        .component__btn-primary {
            float: left;
        }
    }
}
