.file-modal__content {
    background: #ffffff;
    border-radius: 12px;
    height: 50px;
    border: 1px dashed #d8d8d8;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 20px;
    width: 100%;

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #1c1d21;

      .content--link {
        text-decoration-line: underline;
        color: #349beb;
        cursor: pointer;
        font-weight: bold;
      }
    }
  }
  .file-name {
    background: #f5f5f5;
    width: max-content;
    max-width: 450px;
    margin-top: 10px;
    padding: 5px;
    border-radius: 5px;
}
