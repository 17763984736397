@import '../../../../assets/scss/utils/mixins';
@import '../../../../assets/scss/utils/variables';
/* Style for DatePicker.tsx */
.date-picker__container {
    width: 100%;
    position: relative;

    .date-picker__icon-wrapper {
        position: absolute;
        display: flex;
        height: 1.5rem;
        width: 2rem;
        top: 0.5rem;
        right: 1rem;
        background-color: #ffffff;
        box-sizing: border-box;
        box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
        justify-content: flex-end;
        align-items: center;

        .date-picker__icon-clear {
            font-size: 17px;
            &:hover {
                opacity: 0.7;
            }
        }

        &.date-picker__icon-wrapper--material {
            top: 0.45rem !important;
            right: 0.5rem !important;
            .date-picker__icon-clear {
                &:hover {
                    @include gradient-text;
                    cursor: pointer;
                }
            }
        }
    }

    .date-picker__icon {
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 50%;
        color: #ffffff;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        @include background-color;

        &.error-background {
            background: $error-color !important;
        }
        
        i {
            font-size: 12px;
        }
    }

    .ant-picker {
        height: 40px;
        padding: 1rem;
        font-size: 13px;
        border: 0;
        border-radius: 7px;
        box-shadow: none !important;

        &.ant-picker--material {
            padding: 1rem 1rem 1rem 0rem !important;
            border-radius: 0 !important;
            border-bottom: 2px solid $input-border-color;
            background: none !important;
            box-shadow: none !important;
            &:hover, &:focus {
                border-bottom: 2px solid !important;
                @include border-color;
                & ~ i {
                    @include gradient-text;
                }
                +.date-picker__icon-wrapper {
                    .date-picker__icon-clear {
                        @include gradient-text;
                    }
                }
            }
        }

        &.ant-picker--bordered {
            border: 1px solid #F2F2F2;
        }
    }
}
