.dashboard-master__container {
  .header-title{
    color: #2C7BE5;
    z-index: 2;
  }

  .splatter {
    width: 30px;
    height: 30px;
    background: radial-gradient(circle at 50% 50%, rgba(0, 238, 255, 0.8) 30%, transparent 70%);
    border-radius: 50%;
    left: 250px;
    position: absolute;
  }

  .splatter::before {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    background: radial-gradient(circle at 50% 50%, rgba(133, 228, 149, 0.6) 30%, transparent 60%);
    border-radius: 50%;
    top: -20px;
    left: 150px;
    z-index: 1;
  }
  
  .splatter::after {
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    background: radial-gradient(circle at 50% 50%, rgba(255, 0, 0, 0.5) 30%, transparent 60%);
    border-radius: 50%;
    bottom: -30px;
    right: -100px;
  }
  

  .page__body {
    padding: 24px;

    .ant-card {
      border-radius: 20px !important;
    }

    .ant-card-body {
      border-radius: 20px;
      width: 100%;
    }

    .dashboard-master__overview {
      .ant-card-body {
        padding: 27px 20px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 36px;
          height: 36px;
          border-radius: 10px;
          margin-right: 16px;
        }

        .stat {
          font-style: normal;

          h2 {
            font-family: Montserrat;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: 0.116667px;
            color: #171725;
          }

          p {
            font-family: Montserrat;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.1px;
            color: #696974;
          }
        }

        .diff {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.1px;
        }
      }
    }

    .dashboard-master__recent {
      .ant-card-body {
        padding: 0;
        padding-bottom: unset;
      }
    }

    .dashboard-master__end-user {
      img {
        height: 42px;
        width: 42px;
        border-radius: 50%;
      }

      h2 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.1px;
        color: #171725;
      }

      p {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: #92929d;
      }
    }

    .dashboard-master__end-user,
    .dashboard-master__ticket {
      span {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.8px;
        text-transform: uppercase;
        color: #0062ff;
        cursor: pointer;
      }
    }
  }
}
