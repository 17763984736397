.ticket-detail__container {
  .ticket-detail__action--do {
    background: #f2c94c;
  }

  .ticket-detail__input {
    background: #f8f9fb;
    padding: 16px;
    border-radius: 4px;

    textarea {
      padding: 0;
      margin-bottom: 16px;
      resize: none;
      background: #f8f9fb;

      &:focus {
        background: #f8f9fb;
      }
    }

    button {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #ffffff;

      outline: none;
      border-color: #2e499c;
      background-color: #2e499c;
    }
  }

  .ticket-detail__assignee {
    h4 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      color: #979797;
    }

    button {
      border: none;
      outline: none;
      border-radius: 50%;
      width: 32px;
      height: 32px;
    }

    p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #35455C;
      margin-bottom: 0;
      margin-left: 8px;
    }

    .ticket-detail__assignee-info {
      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
    }
  }
}
