/* custom tree */

.ant-card {
  border-radius: 7px !important;
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  @include background-color;
  @include border-color;
}
.ant-tree .ant-tree-switcher-leaf-line {
  &:before {
    border-left: 1px dashed #e0e0e0 !important;
  }
  &:after {
    border-left: 1px dashed #e0e0e0 !important;
  }
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after {
  @include background-color;
}

.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  @include background-active-color;
}

.ant-tree
  .ant-tree-node-content-wrapper.ant-tree-node-selected
  .ant-tree-title {
  @include gradient-text;
}

/* custom tabs*/

.ant-tabs {
  .ant-tabs-nav-wrap {
    .ant-tabs-tab {
      .ant-tabs-tab-btn {
        @include gradient-text();
      }
    }
    .ant-tabs-ink-bar {
      background: #0743fb;
    }
  }
}

/* custom switch*/

.ant-switch-checked {
  @include background-color();
}

/* custom radio */

.ant-radio-checked .ant-radio-inner {
  border-color: #013ffb !important;
}

.ant-radio-inner:after {
  @include background-color();
}

/*custom checkbox */

.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: #013ffb !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  @include background-color();
}

.ant-dropdown-menu {
  border-radius: 7px !important;

  .ant-action-menu {
    padding: 5px 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    text-align: center;
  }
}

.ant-table-tbody {
  .ant-cell-master__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .cell-master__first-row {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      color: #1c1d21;
    }

    .cell-master__second-row {
      margin-top: 5px;
      font-style: normal;
      font-weight: 300;
      font-size: 10px;
      line-height: 16px;
      color: #8181a5;
    }

    .row--ellipsis {
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .row--link {
      color: #2f80ed;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.ant-table-wrapper {
  .ant-table {
    .ant-table-title {
      border: none;
    }
    border-radius: 7px;
    .ant-table-thead {
      tr {
        th {
          border: none !important;
          font-weight: bold;
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner {
      border-color: #365bf8;
    }
    .ant-checkbox-checked .ant-checkbox-inner,
    .ant-checkbox-indeterminate .ant-checkbox-inner:after {
      @include background-color();
    }
    .ant-table-tbody {
      .ant-table-cell {
        color: #1c1d21;
        // font-weight: bold;
        font-size: 13px;
        line-height: 150%;
      }

      > tr > td {
        border: none !important;
      }
    }
  }
}
