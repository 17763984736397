.ticket-conversation {
  display: flex;
  padding-bottom: 24px;

  &:not(:last-child) {
    .ticket-conversation__body {
      padding-bottom: 24px;
      border-bottom: 1px solid #f1f1f5;
    }
  }

  .ticket-conversation__avatar {
    margin-right: 16px;

    img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
    }
  }

  .ticket-conversation__body {
    width: 100%;

    .ticket-conversation__header {
      display: flex;
      justify-content: space-between;

      p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #313748;

        &:last-child {
          font-size: 11px;
          color: #bdbdbd;
        }
      }
    }

    .ticket-conversation__files {
      margin-top: 16px;
      display: flex;
      column-gap: 8px;

      img {
        width: 48px;
        height: 48px;
        border-radius: 4px;
        object-fit: cover;
      }
    }
  }
}

.ticket-conversation-input__files {
  display: flex;
  height: 100%;

  img {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    object-fit: cover;
    margin-left: 8px;
  }
}
.ticket-conversation-input__files {
  display: flex;
  height: 100%;
  column-gap: 8px;
  margin-top: 8px;

  img {
    width: 48px;
    height: 48px;
    border-radius: 4px;
    object-fit: cover;
  }
}
