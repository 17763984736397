@import "../../../../assets/scss/utils/mixins";
/* Style for AdvanceStringFilter.tsx */
.advance-string-filter__container {
  width: 100%;
  border-radius: 7px;
  .advance-string-filter__title {
    @include input-title;
  }
  .advance-string-filter__wrapper {
    widows: 100%;
    position: relative;
    .advance-string-filter__icon {
      @include input-icon;
      font-size: 1rem;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
