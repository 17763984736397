.login-page {
  overflow-y: hidden;
  min-height: 100vh !important;
  background-repeat: no-repeat;
  background-size: cover;
  // background: linear-gradient(0deg, #00579b, #00579b), #ffffff;
  position: relative;
  background-color: transparent;
  // .login-box {
  //   background-image: url('/assets/img/brand/b2.png');
  // }

  /* Change the white to any color ;) */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    transition: background-color 5000s ease-in-out 0s;
  }

  .btn-login {
    max-width: 195px;
    z-index: 5000;
    background: linear-gradient(90deg, #519cfc 0%, #606aee 100%), #ffffff;
    border-radius: 43px;
    padding: 16px 35px 16px 31px;
    font-weight: 600;
    border: none;
  }
  @media screen and (min-width: 1920px) {
    .logo {
      top: 53px;
    }
  }
  .logo {
    // top: 8%;
    margin-left: 53px;
    // position: absolute;
  }

  .flex-container {
    padding: 0;
    margin: 0;
    list-style: none;

    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
  }

  .flex-container-row {
    justify-content: center;
    align-items: center;
  }

  .flex-item {
    margin-top: 1rem;
    // width: 50%;
    line-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .gateway {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      flex-direction: column;
    }
  }
  .info-text {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    color: #ff3a29;
  }
  /* Style for Login.tsx */
  .title {
    // position: absolute;
    // margin-left: 138px;
    top: 20%;
    color: #ffffff;
    .welcome {
      font-weight: bold;
      font-size: 40px;
      line-height: 46px;
    }
    .title-content {
      font-weight: 300;
      font-size: 22px;
      line-height: 23px;
      text-align: center;
    }
  }

  .login-frame {
    z-index: 10;
    // margin-left: 138px;
    top: 30%;
    width: 513px;
    height: 419px;
    max-width: 513px;
    max-height: 419px;
    padding: 30px 44px 30px 34px;
    box-shadow: 0px 15px 40px rgba(0, 87, 155, 0.1);
    border-radius: 30px;
    background-color: #fff;

    @mixin input-login {
      background: #ffffff;
      border: none;
      border-bottom: 1px solid #d9d9d9;
      height: 35px;
      padding: 1px 3px;
      padding-margin-left: 10px;
      border-radius: 0px;
    }
    label {
      color: #646c9a;
      font-size: 21px;
    }

    .login-content {
      .right-inner-addon {
        position: relative;
      }
      .right-inner-addon input {
        padding-right: 35px !important;
      }
      .right-inner-addon img {
        position: absolute;
        right: 0px;
        padding: 0px;
        pointer-events: none;
        z-index: 5001;
      }

      input {
        font-family: inherit !important;
        width: 100%;
        margin: 0em !important;
        box-sizing: border-box;
        font-weight: bold;
        font-size: 15px;
        color: #1c1d21;
      }
      .input-login {
        z-index: 5000;
        // min-width: 300px;
        @include input-login();
        &:focus {
          box-shadow: unset !important;
          outline: none;
        }
      }

      .input-confirm {
        &:focus {
          box-shadow: unset;
          outline: none;
        }
        @include input-login();
      }

      .login {
        text-align: center;
      }
      .forgot-password {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        color: #d42428;
        span {
          z-index: 5000;
          font-weight: 300;
          font-size: 14px;
          line-height: 21px;
          /* identical to box height, or 131% */

          color: #00579b;
        }
        i {
          color: #00579b;
        }
      }

      .title-content {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        font-weight: bold;
        color: #00579b;
        z-index: 5000;
      }
      .action-login {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background-color: transparent;
        margin-top: 5rem;
      }

      .under-title {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.01em;
        color: #00579b;
        margin-bottom: 20px;
        z-index: 5000;
      }

      .pointer:hover {
        cursor: pointer;
      }
      .pointer {
        z-index: 5000;
        color: #00579b;
      }
    }
    .forgot-password-content {
      .title-content {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        font-weight: bold;
        color: #00579b;
        z-index: 5000;
      }

      .under-title {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.01em;
        color: #00579b;
        margin-bottom: 20px;
        z-index: 5000;
      }
      .pointer:hover {
        cursor: pointer;
      }
    }
  }
  @media screen and (min-width: 1920px) {
    // .login-frame {
    //   right: 100px !important;
    //   top: 23%;

    //   .login-content {
    //     .input-login {
    //       min-width: 569px;
    //     }
    //   }
    // }
    .login-frame {
      min-width: 513px;
    }
  }

  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0px 2px 0 0px rgba(236, 70, 73, 0.2);
  }
  .tio-clear {
    color: #ee534d;
    font-size: 20px;
  }
  .tio-done {
    color: #2cde32;
    font-size: 20px;
  }
}

.particles-js {
  background: transparent;
  // position: absolute;
  // height: 100vh;
  // width: 100%;
  // z-index: -1;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  margin-left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.login-error {
  color: #d42428;
}

@media screen and (max-width: 768px) {
  .login-page {
    .logo {
      margin-left: 0px;
    }
    .flex-container {
      display: block;
      .flex-item {
        width: 100%;
        .gateway {
          img {
            width: 25%;
          }
        }
        padding: 20px;
      }
    }
    .title {
      margin-left: 0;
      .welcome {
        font-size: 30px;
      }
      .title-content {
        font-size: 18px;
      }
    }
    .login-frame {
      margin-left: 0px;
    }
  }
}

@media screen and (max-width: 500px) {
  .login-frame {
    width: 367px !important;
    height: 335px !important;
    max-width: 367px !important;
    max-height: 335px !important;
    .action-login {
      margin-top: 1rem !important;
    }
  }
}

