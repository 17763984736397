.breakword {
  word-break: break-all;
}

.certification__container {
  .page__master-table {
    .ant-card {
      .ant-card-body {
        padding: 0 !important;
      }
    }
  }
}
