.dormitory__container {
  height: 100%;

  .page__master-table {
    height: 100%;

    .ant-card {
      .ant-card-body {
        padding: 0 !important;
      }
    }
  }
}
