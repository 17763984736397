.form-container {
  position: "fixed";
  top: "0px";
  left: "0px";
  width: "100%";
  height: "100%";
  background-color: "transparent";
  z-index: 1000;
  display: "flex";
  justify-content: "center";
  align-items: "center";
}
