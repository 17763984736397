.end-user__container {
  .card-title {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #252631;
    text-transform: uppercase;
  }

  .user-info__label-field {
    font-family: Montserrat;
    font-style: normal;
    color: #2e499c;

    &.user-info__label-field--big {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
    }

    &.user-info__label-field--small {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
    }

    &.user-info__label-field--light {
      color: #92929d;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .user-info__label-content {
    font-family: Montserrat;
    font-style: normal;
    font-size: 13px;
    line-height: 16px;
    color: #313748;

    &.user-info__label-content--light {
      font-weight: normal;
    }

    &.user-info__label-content--bold {
      font-weight: bold;
    }
  }

  .user-info__wrapper {
    .ant-card {
      border-radius: 4px !important;
    }

    .user-info__container {
      .user-info__header {
        position: relative;
        margin-bottom: 24px;

        .edit-icon {
          font-size: 18px;
          position: absolute;
          cursor: pointer;
          top: 0px;
          right: 0px;
        }

        .user-info__image {
          position: relative;
          height: 100px;
          width: 100px;

          img {
            height: 100%;
            width: 100%;
          border-radius: 50%;
          }

          .user-info__status {
            position: absolute;
            height: 16px;
            width: 16px;
            bottom: 1px;
            right: 12px;

            img {
              height: 100%;
              width: 100%;
            }
          }
        }
      }

      .user-info__content {
        border-top: 1px solid #f1f1f5;
        border-bottom: 1px solid #f1f1f5;
        padding: 24px 0px;
      }

      .user-info__footer {
        div {
          cursor: pointer;
        }

        .btn-save {
          color: #f8f9fb;
          background: #2e499c;
          border-radius: 10px;
          padding: 8px 16px;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          outline: none;
          border: 0px;
        }

        .btn-cancel {
          background: #f8f9fb;
          border-radius: 10px;
          padding: 8px 16px;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #2e499c;
          outline: none;
          border: 0px;
        }
      }
    }
  }

  .ticket__container {
    background: #ffffff;
    row-gap: 8px;

    .empty-ticket {
      img {
        width: 168px;
        height: 104px;
      }
    }
    .ticket__item {
      border: 1px solid #f1f1f5;
      border-radius: 10px;
      padding: 16px;
    }
  }

  .table__container {
    background-color: #ffffff;
    padding-bottom: 16px;
    border-radius: 4px;

    .table__header {
      padding: 16px;
    }

    .table__content {
      tr {
        td {
          border-bottom: none;
        }
      }
    }
  }
}

.activation__container {
  width: 100%;
  height: auto;
  max-height: 500px;
  overflow-y: auto;

  .activation__item {
    margin-bottom: 24px;
    .item__date {
      div {
        background: #dce7ff;
        border-radius: 4px;
        color: #2e499c;
        padding: 8px 16px;
        white-space: nowrap;
      }
    }

    .item__content {
      flex-grow: 1;
      overflow-x: hidden;

      .content__user-name {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #252631;
        width: fit-content;
        white-space: nowrap;
      }

      .content__info {
        flex-grow: 1;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #778ca2;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .content__time {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        color: #313748;
        white-space: nowrap;
      }
    }
  }
}

.activation__footer {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #313748;
  cursor: pointer;
  i {
    font-size: 14px;
  }
}

.lock-account-modal__contaier {
  padding: 24px;

  .lock-account-modal__header {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #313748;
    text-transform: uppercase;
  }

  .lock-account-content {
    img {
      width: 170px;
      height: 100px;
    }

    .content__title {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #e91616;

      &.content__title--unlock {
        color: #2e499c;
      }
    }

    .content__description {
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      color: #313748;
      mix-blend-mode: normal;
      opacity: 0.7;
      white-space: wrap;
      max-width: 100%;
    }
  }

  .lock-account-footer {
    .btn-save {
      border: 0px;
      outline: none;
      cursor: pointer;
      background: #dc3545;
      border-radius: 7px;
      padding: 8px 16px;
      color: #ffffff;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;

      &.btn-save--unlock {
        background: #2e499c;
      }
    }

    .btn-cancel {
      outline: none;
      background: #ffffff;
      border: 1px solid #2e499c;
      box-sizing: border-box;
      border-radius: 7px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: #2e499c;
    }
  }
}

.forgot-password-modal__contaier {
  padding: 24px;

  .forgot-password-modal__header {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #313748;
    text-transform: uppercase;
  }

  .forgot-password-footer {
    .btn-save {
      border: 0px;
      outline: none;
      cursor: pointer;
      background: #2e499c;
      border-radius: 7px;
      padding: 8px 16px;
      color: #ffffff;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
    }

    .btn-cancel {
      outline: none;
      background: #ffffff;
      border: 1px solid #2e499c;
      box-sizing: border-box;
      border-radius: 7px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: #2e499c;
    }
  }
}

.activation-modal__container {
  .activation-modal__header {
    padding: 16px;
    border-bottom: 1px solid #f2f2f2;
    span {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #252631;
    }
    div {
      color: #313748;
      background: #f8f9fb;
      border-radius: 50%;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .activation-modal__body {
    padding: 16px 24px;

    .body__filter-area {
      width: 100%;
    }

    .body__content-area {
      width: 100%;
      margin-top: 24px;
    }
  }
}
