.app-user__container {
  height: 100%;

  .page__master-table {
    height: 100%;

    .ant-card {
      .ant-card-body {
        padding: 0 !important;
      }
    }
  }

  .app-user__email {
    color: #2f80ed !important;
    text-decoration: underline;
  }

  .app-user__role {
    display: flex;
    column-gap: 4px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #f8f9fb;

    span {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4px 8px;
      height: 24px;
      border-radius: 6px;

      &.app-user--admin {
        background-color: #333;
      }

      &.app-user--operator {
        background-color: #2e499c;
      }

      &.app-user--supporter {
        background-color: #f2c94c;
        color: #313748;
      }

      &.app-user--viewer {
        background-color: #6fcf97;
      }

      &.app-user--add {
        background-color: #f8f9fb;
        width: 24px;
        padding: 0;
        border-radius: 50%;
        color: #313748;
      }
    }
  }

  .app-user__status {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    border-radius: 7px;
    width: fit-content;
    font-family: Montserrat;
    font-style: normal;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;

    &.active {
      background: #dffff2;
      color: #07bb4a;
    }

    &.inactive {
      background: #ffd7da;
      color: #dc3545;
    }
  }
}
