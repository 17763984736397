@import "../../../../assets/scss/utils/mixins";
/* Style for InputSelect.tsx */
.input-select__container {
  width: 100%;
  position: relative;
  .input-select__icon {
    &:hover {
      opacity: 0.7;
    }
  }
}
