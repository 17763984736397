.home__container {
  .left-side__container {
    background-color: #ffffff;
    border-radius: 6px;

    .left-side__image {
      width: 100%;
      height: 184px;

      img {
        width: 100%;
        height: 184px;
      }
    }

    .left-side__location {
      padding: 16px 24px;

      .location__header {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #2e499c;
        margin-bottom: 24px;
      }

      .location__content {
        border-top: 1px solid #f1f1f5;
        border-bottom: 1px solid #f1f1f5;
        padding: 24px 0px;
        .content__location {
          .location__label {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: #92929d;
          }

          .location__info {
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 16px;
            letter-spacing: 0.1px;
            color: #171725;
          }
        }

        .content__map {
          width: 100%;
          height: 310px;
        }
      }
    }

    .left-side__action {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #313748;
      cursor: pointer;
      padding: 12px 24px 26px 24px;
    }
  }

  .right-side__container {
    .right-side__header {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 32px;
      display: flex;
      align-items: center;
      color: #313748;
      margin-bottom: 16px;
    }

    .right-side__user-list {
      max-width: 100%;
      overflow-x: auto;
      margin-bottom: 24px;

      .user__item {
        padding: 24px 0px;
        width: 168px;
        height: 180px;
        background-color: #ffffff;
        margin-right: 24px;
        flex: 0 0 auto;

        .user__avatar {
          width: 64px;
          height: 64px;
          img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
          }
        }

        .user__name {
          margin-top: 4px;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          color: #0a1629;
        }

        .user_role {
          margin-top: 4px;
          margin-bottom: 8px;
          font-style: normal;
          font-weight: normal;
          font-size: 10px;
          line-height: 16px;
          text-align: center;
          color: #0a1629;
        }

        .user_action {
          background: #dce7ff;
          border-radius: 10px;
          padding: 4px 7px;
          cursor: pointer;
          span {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: #2e499c;
          }
        }
      }
    }

    .right-side__home-controller {
      margin-bottom: 1em;
      width: 100%;
      height: 450px;
      background-color: #ffffff;

      .home-controller__title {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        color: #313748;
        padding: 16px;
      }

      .home-controller__table {
        width: 100%;
        tr {
          td {
            border-bottom: none;
          }
        }
      }
    }
  }
}

.supporter-modal__container {
  padding: 24px;

  .supporter-modal__header {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #313748;
    text-transform: uppercase;
  }

  .supporter-footer {
    .btn-save {
      border: 0px;
      outline: none;
      cursor: pointer;
      background: #2e499c;
      border-radius: 7px;
      padding: 8px 16px;
      color: #ffffff;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
    }

    .btn-cancel {
      outline: none;
      background: #ffffff;
      border: 1px solid #2e499c;
      box-sizing: border-box;
      border-radius: 7px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: #2e499c;
    }
  }
}
