.end-user__container {
  .end-user__status {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    border-radius: 7px;
    width: fit-content;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;

    &.end-user__status--active {
      background: #dffff2;
      color: #07bb4a;
    }

    &.end-user__status--inactive {
      background: #ffd7da;
      color: #dc3545;
    }
  }

  .open-ticket {
    border: 1px solid #f1f1f5;
    border-radius: 10px;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;

    h2 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.1px;
      color: #171725;
    }

    span {
      text-decoration-line: underline;
      color: #2f80ed;
      cursor: pointer;
    }
  }

  .list-circle__container {
    width: 100%;
    height: 40px;
    position: relative;

    .circle-item {
      width: 36px;
      height: 36px;
      border: 2px solid #ffffff;
      border-radius: 50%;
      position: absolute;
      background-color: #f2f4f6;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }

      span {
        font-size: 14px;
        line-height: 21px;
        color: #98a9bc;
      }
    }
  }

  .table__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

}