@import "../../../assets/scss/utils/mixins";
/* Style for TextArea.tsx */
.text-area__container {
  width: 100%;
  position: relative;
  background-color: #f2f2f2;
  
  .text-area__title {
    @include input-title;
  }

  .text-area__icon {
    &:hover {
      opacity: 0.7;
    }
  }
}
