.home-controller__status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  width: fit-content;
  border-radius: 7px;

  &.home-controller__status--2 {
    background: #ffd7da;
    color: #dc3545;
  }

  &.home-controller__status--1 {
    background: #dffff2;
    color: #07bb4a;
  }
}
