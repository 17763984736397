/* .modal__container .modal_content .preview__containter .preview__left-side { */
/*   overflow-y: scroll; */
/* } */

.modal__container
  .modal_content
  .preview__containter
  .preview__left-side
  .preview__body {
  max-height: 700px;
}
