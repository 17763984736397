/*! -----------------------------------------------------------------------------------

    Template Name: Cuba Admin
    Template URI: http://admin.pixelstrap.com/cuba/theme
    Description: This is Admin theme
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap

-----------------------------------------------------------------------------------


 */

// *** utils ***
@import "utils/variables";
@import "utils/mixins";
@import "utils/animate";
@import "utils/customize-antd";

:root {
  --theme-deafult: #2e499c;
  --theme-secondary: #f73164;
}

// *** base ***
@import "base/reset";
@import "base/typography";

//  *** components ***
@import "components/according";
@import "components/alert";
@import "components/avatars";
@import "components/badge";
@import "components/bookmark";
@import "components/breadcrumb";
@import "components/builders";

@import "components/buttons";

@import "components/card";
@import "components/color";
@import "components/datepicker";
@import "components/dropdown";
@import "components/datatable";
// @import "components/form-builder";
@import "components/form-input";
@import "components/form-wizard";
@import "components/forms";
// @import "components/form_builder-2";
@import "components/icons";
@import "components/list";
@import "components/loader";
@import "components/popover";
@import "components/print";
@import "components/radio";
@import "components/ribbon";
@import "components/switch";
@import "components/tab";
@import "components/table";
@import "components/touchspin";
@import "components/tour";
@import "components/typeahead-search";
@import "components/react-plugin";
@import "components/slick.scss";
@import "components/slick-theme.scss";

//	*** pages ***

@import "pages/blog";
@import "pages/bookmark-app";
@import "pages/cart";
@import "pages/chart";
@import "pages/chat";
@import "pages/checkout";
@import "pages/comingsoon";
@import "pages/contacts";
@import "pages/calendar";
@import "pages/dashboard_2";
@import "pages/dashboard_default";
@import "pages/ecommerce";
@import "pages/email-application";
@import "pages/errorpage";
@import "pages/faq";
@import "pages/file";
@import "pages/gallery";
@import "pages/job-search";
@import "pages/kanban";
@import "pages/knowledgebase";
@import "pages/language";
@import "pages/learning";
@import "pages/login";
@import "pages/megaoption";
@import "pages/order-history";
@import "pages/page";
@import "pages/pricing";
@import "pages/progress";
@import "pages/projectlist";
@import "pages/social-app";
@import "pages/task";
@import "pages/timeline-v";
@import "pages/timeliny";
@import "pages/user-profile";
@import "pages/wishlist";

// *** layout ***

@import "layout/footer";
@import "layout/grid";
@import "layout/header";
@import "layout/search";
@import "layout/sidebar";
@import "layout/rtl";
@import "layout/box-layout";

/* ---------------------
	*** themes ***
-----------------------*/
@import "themes/dark";
@import "themes/dark-sidebar";
@import "themes/theme-customizer";
@import "themes/responsive";

/* ---------------------
	*** custom scroll bar ***
-----------------------*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px $light-gray;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(68, 102, 242, 0.15);
}

/* ---------------------
	*** custom body ***
-----------------------*/

body {
  background: #f2f2f2;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  height: auto !important;

  .text-upper {
    text-transform: uppercase;
  }

  .menu-title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #2e499c;
  }

  .menu-content {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 9px;
    line-height: 16px;
    color: #313748;
  }

  .show-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .show-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .show-exit {
    opacity: 1;
  }
  .show-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }

  .disabled-field {
    cursor: not-allowed;
  }

  .overlay-backdrop {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;

    img {
      background-color: transparent;
      height: 150px;
      width: 200px;
    }
  }

  .img-loading {
    max-height: 50px;
    max-width: 50px;
  }

  .img-emty {
    width: inherit;
  }

  .input-icon {
    @include input-icon;
  }

  .component__title {
    @include input-title;
  }

  .gradient-text {
    @include gradient-text();
  }

  .icon-fontsize {
    font-size: 20px;
  }

  .component__text-area {
    width: 100%;
    min-height: 40px;
    padding: 0.5rem;
    font-size: 13px;
    font-weight: 400;
    background: #ffffff;
    border: 0;
    border-radius: 7px;
    box-sizing: border-box;
    box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
    outline: none;

    &.component__text-area--bordered {
      border: 1px solid #f2f2f2;
    }

    &.component__text-area--material {
      padding-left: 0rem !important;
      padding-right: 1.25rem !important;
      border-radius: 0 !important;
      border-bottom: 2px solid $input-border-color;
      background: none !important;
      box-shadow: none !important;

      &:hover,
      &:focus {
        border-bottom: 2px solid !important;
        @include border-color;
        & ~ i {
          @include gradient-text;
        }
      }
    }
  }

  .component__input {
    width: 100%;
    height: 40px;
    padding: 1rem 1.75rem 1rem 0.75rem;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-clip: padding-box;
    border: 0;
    box-sizing: border-box;
    box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    outline: none;

    &.component__input--bordered {
      border: 1px solid #f2f2f2;
    }

    &.component__input--material {
      padding: 1rem 2rem 1rem 0rem !important;
      border-radius: 0 !important;
      border-bottom: 2px solid $input-border-color;
      background: none !important;
      box-shadow: none !important;

      &:hover,
      &:focus {
        border-bottom: 2px solid;
        @include border-color;
        & ~ i {
          @include gradient-text;
        }
      }
    }

    &.disabled-field {
      border-bottom: 2px solid transparent !important;
    }
  }

  .btn {
    white-space: nowrap;
    color: inherit;
    &:focus {
      box-shadow: none;
    }
  }

  .component__btn-primary {
    @include background-color;
    color: $white;
    border: none;
    border-radius: 7px;
    text-align: center;
    height: 38px;
    font-weight: 600;
    padding: 0 16px;

    &.component__btn-primary--danger {
      background-color: #dc3545 !important;
    }

    &:hover {
      color: $white;
    }

    i {
      margin-right: 10px;
    }
  }

  .component__btn-toggle {
    background: #fff;
    box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
    box-sizing: border-box;
    height: 38px;
    border-radius: 7px;
    border: none;

    i:not(.reset-icon),
    span:not(.reset-label) {
      @include gradient-text();
    }

    i {
      margin-right: 10px;
    }
    span {
      font-weight: bold;
    }

    &.component__btn-toggle-active {
      @include background-color();
      border: none;
      i,
      span {
        @include white-text();
      }
    }

    &:not(.reset-filter):hover {
      @include background-color();
      border: none;
      i,
      span {
        @include white-text();
      }
    }
  }

  .component__btn-toggle.reset-filter {
    i,
    span {
      color: white;
    }
  }

  .component__btn-outline-primary {
    border: 1px solid;
    @include border-radius-color;
    border-radius: 7px;
    height: 38px;
    margin: 0 auto;
    @include gradient-text;
    font-weight: 500;
    &:hover {
      border: 1px solid transparent;
      @include background-color;
      color: #ffffff;
      -webkit-background-clip: initial;
      -moz-background-clip: initial;
      background-clip: initial;
      -webkit-text-fill-color: initial;
    }
  }

  .component__btn-cancel {
    @include background-color-delete();
    color: #ffffff;
    color: $white;
    border: none;
    border-radius: 7px;
    text-align: center;
    height: 38px;
    font-weight: 600;
    padding: 0 16px;
    &:hover {
      color: $white;
    }
  }

  .component__btn-save-template {
    background: #f2f2f2;
    color: #f5d117;
  }

  .component__btn-save-draft {
    background-color: #f2f2f2;
    span {
      @include gradient-text();
    }
  }

  .gradient-btn-icon {
    @include gradient-text();
    font-size: 20px;
  }

  .component__btn-delete {
    font-size: 20px;
    @include gradient-text-delete();
  }
  /* Page*/
  /* Master*/
  .page {
    &.page__master,
    &.page__detail {
      padding-top: 20px;

      .tab-container {
        padding: 0 24px;
        .ant-tabs {
          background-color: #fff;
          .ant-tabs-nav {
            margin: 0px !important;
            padding: 20px 24px 0px 24px;
            &::before {
              border-bottom: 0px !important;
            }
          }
          .ant-table-title {
            padding: 10px !important;
          }
        }
      }

      .page__header {
        padding: 0px 24px;
        font-weight: bold;
        font-size: 30px;
        .page__title {
          @include gradient-text();
        }
        .page__id {
          color: #4f4f4f;
        }
      }
      .page__modal-header {
        padding: 10px 24px;
        font-weight: bold;
        font-size: 18px;
        .page__title {
          @include gradient-text();
        }
        .page__id {
          color: #4f4f4f;
        }
      }
      .page__search {
        .ant-card {
          background-color: transparent;
        }
        .ant-card-head {
          border-bottom: none;
        }
        .ant-card-body {
          .ant-row {
            background-color: #fff;
            border-radius: 7px;
            padding: 20px;
          }
        }
      }
      .label-detail {
        width: 100%;
        font-size: 13px;
        line-height: 150%;
        color: #8181a5;
        margin-bottom: 2px;
      }
      .page__detail-tabs {
        .ant-tabs {
          overflow: unset !important;
        }
      }
      .page__detail-invoice {
        .page__detail-invoice-header {
          background: #fafafc;
          border-radius: 8px;
          padding: 0px 14px;
          .invoice__row {
            @include gradient_text();
            font-weight: bold;
            font-size: 18px;
          }
        }
      }
      .invoice__delete {
        @include gradient-text-delete();
        font-weight: 600;
      }
      .invoice__add-row {
        .invoice__add-row-text {
          @include gradient-text();
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      .page__detail-total {
        border: 1px solid #eeeeee;
        box-sizing: border-box;
        border-radius: 12px;
        padding: 20px;
      }
      .page__detail-text-total {
        @include gradient-text();
        font-weight: 600;
      }
      .page__detail-card-right {
        max-height: 373px;
        font-size: 18px;
      }
    }
    .page__master-table {
      padding: 0px 24px;
      .ant-card {
        height: 100%;
      }
      .table-title {
        color: #4f4f4f;
        font-weight: bold;
        font-size: 18px;
        line-height: 27px;
      }
      .ant-card-body {
        padding: 0px;
      }
    }
  }

  .mt__1 {
    margin-top: -1.8rem !important;
  }

  .label {
    color: #4f4f4f;
    font-weight: bold;
    font-size: 13px;
    line-height: 150%;
  }

  // status
  @mixin state {
    font-size: 12px;
    text-transform: none;
    border-radius: 8px;
    padding: 7px 19px 7px 19px;
    white-space: nowrap;
    width: 100px;
    text-align: center;
  }

  .shipped {
    @include state();
    background: linear-gradient(
        0deg,
        rgba(94, 129, 244, 0.1),
        rgba(94, 129, 244, 0.1)
      ),
      #ffffff;
    background: -moz-linear-gradient(
        0deg,
        rgba(94, 129, 244, 0.1),
        rgba(94, 129, 244, 0.1)
      ),
      #ffffff;
    background: -webkit-linear-gradient(
        0deg,
        rgba(94, 129, 244, 0.1),
        rgba(94, 129, 244, 0.1)
      ),
      #ffffff;
    color: #2f80ed;
  }

  .processing {
    @include state();
    color: #f2c94c;
    background: linear-gradient(
        0deg,
        rgba(244, 190, 94, 0.1),
        rgba(244, 190, 94, 0.1)
      ),
      #ffffff;
    background: -moz-linear-gradient(
        0deg,
        rgba(244, 190, 94, 0.1),
        rgba(244, 190, 94, 0.1)
      ),
      #ffffff;
    background: -webkit-linear-gradient(
        0deg,
        rgba(244, 190, 94, 0.1),
        rgba(244, 190, 94, 0.1)
      ),
      #ffffff;
  }

  .cancelled {
    @include state();
    color: #eb5757;
    background: linear-gradient(
        0deg,
        rgba(255, 128, 139, 0.1),
        rgba(255, 128, 139, 0.1)
      ),
      #ffffff;
    background: -moz-linear-gradient(
        0deg,
        rgba(255, 128, 139, 0.1),
        rgba(255, 128, 139, 0.1)
      ),
      #ffffff;
    background: -webkit-linear-gradient(
        0deg,
        rgba(255, 128, 139, 0.1),
        rgba(255, 128, 139, 0.1)
      ),
      #ffffff;
  }
  .error-message {
    font-size: 0.8rem;
  }

  .error-border {
    border-color: $error-color !important;
  }

  .error-text {
    color: $error-color !important;
  }

  .error-background {
    background-color: $error-color !important;
  }
  /* custom app main master title */

  .table__action {
    margin-left: 10px;
    .ant-btn {
      height: 40px;
      border-radius: 7px;
      color: #013ffb;
      font-weight: bold;
      border: none;
      box-shadow: 0px 0px 20px #e3e4eb;
      display: flex;
      justify-content: center;
      align-items: center;
      .dropdown {
        transition: all 350ms ease-in-out;
        transform: rotate(180deg);
      }
      &:focus {
        color: #013ffb;
      }
    }
  }

  .action__dots {
    color: #013ffb;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
  }

  .ant-dropdown-menu-item {
    text-align: center;
  }

  .w70 {
    width: 70%;
  }

  .input-search__container .input-search__wrapper .component__input {
    background-color: #fff !important;
  }

  .reset-filter {
    background-color: #ff7283;
    border-radius: 50px;
    color: white !important;
  }

  .text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
  }

  @mixin state {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    padding: 8px 16px;
    border-radius: 7px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    width: fit-content;
  }

  .active-state {
    @include state;
    color: #07bb4a;
    background-color: #dffff2;
  }

  .draft-state {
    @include state();
    color: #778ca2;
    background: linear-gradient(0deg, #f2f4f6, #f2f4f6), #ffffff;
  }

  .pending-state {
    @include state();
    background: linear-gradient(
        0deg,
        rgba(255, 231, 186, 0.5),
        rgba(255, 231, 186, 0.5)
      ),
      #ffffff;
    color: #ffb531;
  }

  .approved-state {
    @include state();
    color: #07bb4a;
    background: rgba(7, 187, 74, 0.1);
  }

  .reject-state {
    @include state();
    color: #e91616;
    background: linear-gradient(
        0deg,
        rgba(255, 128, 139, 0.1),
        rgba(255, 128, 139, 0.1)
      ),
      #ffffff;
  }
}
.text-link {
  text-decoration: underline;
  color: #2f80ed !important;
  &:hover {
    cursor: pointer;
  }
}
